import React from 'react'
import Layout from '../components/layout2'

const NotFoundPage = () => (
  <Layout>
    
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>UNDER CONSTRUCTION</h1>
                <p>It will be online very soon. :)</p>
                <ul className="actions fit">
                  <li><a href="/" className="button fit">Back to the home page</a></li>
                </ul>
            </div>
            
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
